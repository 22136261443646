import React from "react";
import styled from "styled-components";
import Content from '../content';
import XButton from "../images/x-button.png";

const Modal = ({selectedModal, onCloseModal}) => {
  const content = Content[selectedModal];

  if (!content) return null;

  const {title, artist, link, description, bio, image} = content;

  return (
    <Modal.Root>
      <Modal.SizingWrapper>
      <Modal.Container>
        <Modal.Close src={XButton} alt="close" onClick={onCloseModal} />
        <Modal.Content>
          <Modal.TextSection>
            <Modal.Title>{title}</Modal.Title>
            {artist && <Modal.ProjectArtist>{artist}</Modal.ProjectArtist>}
            {link && <Modal.WorkLink href={link}>Visit Work</Modal.WorkLink>}
            <div>
              <Modal.Body>{description}</Modal.Body>
              {bio && <Modal.Body><b>Bio</b></Modal.Body>}
              {bio && <Modal.Body>{bio}</Modal.Body>}
            </div>
          </Modal.TextSection>
          {image && <Modal.ImageSection>
            <Modal.ProjectImage src={image.src} alt={image.alt} />
          </Modal.ImageSection>}
        </Modal.Content>
      </Modal.Container>
      </Modal.SizingWrapper>
    </Modal.Root>
  );
};

Modal.Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;

  @media screen and (max-width: 896px) and (orientation: landscape) {
    padding: 1rem;
  }
`;

Modal.SizingWrapper = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @media screen and (max-width: 896px) and (orientation: landscape) {
    flex: 1;
    margin: 0;
    justify-content: unset;
    align-items: unset;
  }
`;

Modal.Container = styled.div`
  background: white;
  border: 10px solid #525252;
  border-radius: 10px;
  box-shadow: 3px 10px 50px 6px #525252;
  margin: 1rem;
  display: flex;
  position: relative;
  flex: 1;
  max-width: 65%;
  max-height: 80%;

  @media screen and (max-width: 896px) and (orientation: landscape) {
    flex: 1;
    margin: 0;
    max-width: unset;
    max-height: unset;
  }
`;

Modal.Content = styled.div`
  display: flex;
  flex: 1;
  padding: 0rem 1rem;
  
  @media only screen and (max-width: 896px) {
    flex-direction: column;
    overflow-y: scroll;
  }
`;

Modal.Close = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  height: 150px;

  &:hover {
    height: 175px;
    transition: height 0.1s ease;
  }
`;

Modal.TextSection = styled.div`
  flex: 1;
  overflow-y: scroll;
  min-width: min-content;
  padding: 2rem 1rem 2rem 0rem;

  @media only screen and (max-width: 896px) {
    width: 100%;
    overflow-y: visible;
    padding: 1rem 0rem;
  }
`;

Modal.ImageSection = styled.div`
  flex: 2;
  padding: 2rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 896px) {
    width: 100%;
    overflow: visible;
    padding: 0;
  }
`;

Modal.Title = styled.h1`
  text-transform: uppercase;
  font-family: 'Kanit';
  font-weight: 800;
  line-height: 0.95;
  font-size: 4rem;
  padding: 0;
  margin: 0 0 1.5rem 0;
`;

Modal.ProjectArtist = styled.h5`
  font-family: 'Kanit', sans-serif;
  font-size: 2rem;
  padding: 0;
  margin: 0 0 1.5rem 0;
`;

Modal.WorkLink = styled.a`
  font-family: 'Kanit', sans-serif;
  text-transform: uppercase;
  color: black;
  font-size: 2rem;
  margin: 0 0 1.5rem 0;
  display: inline-block;
`;

Modal.Body = styled.p`
  font-family: 'Kanit', sans-serif;
  font-size: 1rem;
`;

Modal.ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default Modal;