import React, {useState}  from "react";
import styled, {keyframes} from "styled-components";
import BackgroundGenes from "../images/BackgroundGenes1.png";
import Genes1 from "../images/Genes1.png";
import Genes2 from "../images/Genes2.png";
import Rhizome from "../assets/logos/Rhizome_logo.svg";
import CLab from "../assets/logos/CLab_logo.svg";
import RotatePhone from "../images/RotatePhoneImage2.png";
import RotatePhoneBackground from "../images/RotatePhoneBackground.png";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Highlights from '../components/hightlights';
import Modal from '../components/modal';

const IndexPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedModal, setSelectedModal] = useState(null);

  const onOpenModal = (e) => {
    const project = e.target.dataset.modal;
    setSelectedModal(project);
    setIsModalOpen(true);
  }

  const onCloseModal = () => {
    setSelectedModal(null);
    setIsModalOpen(false);
  }

  return (
    <Layout>
      <SEO title="Forking Piragene" />
      <IndexPage.DesktopLayout>
        <IndexPage.BackgroundImageContainer zIndex={-3}>
          <img src={BackgroundGenes} />
        </IndexPage.BackgroundImageContainer>
        <Highlights highlightZIndex={-2} onOpenModal={onOpenModal} />
        <IndexPage.BackgroundImageContainer zIndex={0}>
          <IndexPage.Gene1 src={Genes1} />
        </IndexPage.BackgroundImageContainer>
        <IndexPage.BackgroundImageContainer zIndex={0}>
          <IndexPage.Gene2 src={Genes2} />
        </IndexPage.BackgroundImageContainer>
        <IndexPage.TitleSection>
          <IndexPage.PageTitleText>Forking <br/> Piragene</IndexPage.PageTitleText>
          <IndexPage.SubtitleText>Part of Shu Lea Cheang’s Lab Kill Lab</IndexPage.SubtitleText>
          <IndexPage.LogoSection>
            <IndexPage.Rhizome>
              <Rhizome />
            </IndexPage.Rhizome>
            <IndexPage.CLab>
              <CLab />
            </IndexPage.CLab>
          </IndexPage.LogoSection>
        </IndexPage.TitleSection>
        {isModalOpen && <Modal selectedModal={selectedModal} onCloseModal={onCloseModal} />}
      </IndexPage.DesktopLayout>
      <IndexPage.MobileLayout>
        <IndexPage.MobileContentContainer>
          <IndexPage.TitleSection>
            <IndexPage.MobileTitleText>Forking <br/> Piragene</IndexPage.MobileTitleText>
            <IndexPage.MobileSubtitleText>Please rotate your phone</IndexPage.MobileSubtitleText>
          </IndexPage.TitleSection>
          <IndexPage.RotateImageContainer>
            <IndexPage.RotatePhoneImage src={RotatePhone} alt="Image of a phone rotating into landscape mode" />
          </IndexPage.RotateImageContainer>
        </IndexPage.MobileContentContainer>
      </IndexPage.MobileLayout>
    </Layout>
  );
}

IndexPage.BackgroundImageContainer = styled.div`
  height: 100%;
  min-height: 1000px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  
  img {
    height: 100%;
    z-index: ${p => p.zIndex};
  }
`;

const geneHide = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

IndexPage.Gene1 = styled.img`
  animation: ${geneHide} 2s infinite;
`;

IndexPage.Gene2 = styled.img`
  animation: ${geneHide} 2s infinite;
  animation-delay: 1s;
`;

IndexPage.TitleSection = styled.div`
  padding: 2rem;
  font-family: 'Kanit', sans-serif;
  font-weight: 800;
  line-height: 0.95;
  max-width: min-content;
  position: relative;
  z-index: 100;
`;

IndexPage.PageTitleText = styled.h1`
  text-transform: uppercase;
  font-size: 6.25rem;
  margin: 0;
`;

IndexPage.SubtitleText = styled.h4`
  font-size: 1.75rem;
  margin: 0;
`;

IndexPage.LogoSection = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;

  svg {
    height: 2rem;
    flex: 1;
  }
`;

IndexPage.Rhizome = styled.div`
  height: 30px;

  svg {
    height: 100%;
  }
`;

IndexPage.CLab = styled.div`
  background: black;
  padding: 0.5rem;
  margin-left: 0.5rem;
  svg {
    height: 30px;
    fill: #fff;
  }
`;

IndexPage.DesktopLayout = styled.div`
  display: block;

  @media screen and (max-width: 896px) and (orientation: portrait) {
    display: none;
  }
`;

IndexPage.MobileLayout = styled.div`
  display: none;
  height: 100%;
  width: 100%;
  background-image: url(${RotatePhoneBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 896px) and (orientation: portrait) {
    display: block;
  }
`;

IndexPage.MobileContentContainer = styled.div`
  padding-top: 20%;
`;

IndexPage.MobileTitleText = styled.h1`
  text-transform: uppercase;
  font-size: 17vw;
  margin: 0;
`;

IndexPage.MobileSubtitleText = styled.p`
  font-family: 'Kanit', sans-serif;
  font-weight: 200;
  font-size: 1.25rem;
  margin: 1rem 0 2rem 0;
`;

IndexPage.RotateImageContainer = styled.div`
  margin: 0 15%;
`;

IndexPage.RotatePhoneImage = styled.img`
  width: 100%;
`;

export default IndexPage;
