import React, {useState} from "react";
import styled from "styled-components";
import Highlight0 from "../images/highlights/Highlight0.png";
import Highlight1 from "../images/highlights/Highlight1.png";
import Highlight2 from "../images/highlights/Highlight2.png";
import Highlight3 from "../images/highlights/Highlight3.png";
import Highlight4 from "../images/highlights/Highlight4.png";
import Highlight5 from "../images/highlights/Highlight5.png";
import Highlight6 from "../images/highlights/Highlight6.png";
import Highlight7 from "../images/highlights/Highlight7.png";

const highlights = [
  Highlight0,
  Highlight1,
  Highlight2,
  Highlight3,
  Highlight4,
  Highlight5,
  Highlight6,
  Highlight7,
];

const Highlights = ({highlightZIndex, onOpenModal}) => {
  const [visibleHighlightIndex, setVisibleHighlightIndex] = useState(0);

  const onShowHighlight = e => {
    const highlightStrandIndex = Number(e.target.dataset.highlightStrand);
    setVisibleHighlightIndex(highlightStrandIndex);
  };

  const onHideHighlight = () => setVisibleHighlightIndex(0);

  return (
    <Highlights.BackgroundImageContainer zIndex={highlightZIndex}>
      <img src={highlights[visibleHighlightIndex]} />
      <Highlights.BookOfMine
        data-highlight-strand="1"
        data-modal="bookofmine"
        onClick={onOpenModal}
        onMouseEnter={onShowHighlight}
        onMouseLeave={onHideHighlight}
      >
        <Highlights.Text>
          <Highlights.ProjectTitle>A Book of Mine</Highlights.ProjectTitle> (2019-2020)
        </Highlights.Text>
        <Highlights.Text>Xin Liu</Highlights.Text>
      </Highlights.BookOfMine>
      <Highlights.Curatorial
        data-highlight-strand="7"
        data-modal="curatorial"
        onClick={onOpenModal}
        onMouseEnter={onShowHighlight}
        onMouseLeave={onHideHighlight}
      >
        <Highlights.Text>Curatorial Concept</Highlights.Text>
      </Highlights.Curatorial>
      <Highlights.MatterFarm
        data-highlight-strand="5"
        data-modal="matterfarm"
        onClick={onOpenModal}
        onMouseEnter={onShowHighlight}
        onMouseLeave={onHideHighlight}
      >
        <Highlights.Text>
          <Highlights.ProjectTitle>Matter.Farm</Highlights.ProjectTitle> (2018)
        </Highlights.Text>
        <Highlights.Text>Sean Raspet & Francis Tseng</Highlights.Text>
      </Highlights.MatterFarm>
      <Highlights.MutantGarden
        data-highlight-strand="3"
        data-modal="mutantgarden"
        onClick={onOpenModal}
        onMouseEnter={onShowHighlight}
        onMouseLeave={onHideHighlight}
      >
        <Highlights.Text>
          <Highlights.ProjectTitle>mutant garden</Highlights.ProjectTitle> (2020)
        </Highlights.Text>
        <Highlights.Text>Harm van den Dorpel</Highlights.Text>
      </Highlights.MutantGarden>
      <Highlights.ElectronicDiaries
        data-highlight-strand="4"
        data-modal="electronicdiaries"
        onClick={onOpenModal}
        onMouseEnter={onShowHighlight}
        onMouseLeave={onHideHighlight}
      >
        <Highlights.Text>
          <Highlights.ProjectTitle>The Electronic Diaries</Highlights.ProjectTitle> (1984-2019)
        </Highlights.Text>
        <Highlights.Text>Lynn Hershman Leeson</Highlights.Text>
      </Highlights.ElectronicDiaries>
      <Highlights.TTTTT
        data-highlight-strand="2"
        data-modal="ttttt"
        onClick={onOpenModal}
        onMouseEnter={onShowHighlight}
        onMouseLeave={onHideHighlight}
      >
        <Highlights.Text>
          <Highlights.ProjectTitle>DNA: TTTT / RNA: AAAA!</Highlights.ProjectTitle> (2020)
        </Highlights.Text>
        <Highlights.Text>Devin Kenny</Highlights.Text>
      </Highlights.TTTTT>
      <Highlights.Credits
        data-highlight-strand="6"
        data-modal="credits"
        onClick={onOpenModal}
        onMouseEnter={onShowHighlight}
        onMouseLeave={onHideHighlight}
      >
        <Highlights.Text>Credits</Highlights.Text>
      </Highlights.Credits>
    </Highlights.BackgroundImageContainer>
  );
};

Highlights.BackgroundImageContainer = styled.div`
  height: 100%;
  min-height: 1000px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  img {
    z-index: ${p => p.zIndex};
    height: 100%;
  }
`;

Highlights.TextGroup = styled.a`
  color: white;
  font-family: 'Kanit', sans-serif;
  font-weight: 800;
  line-height: 0.95;
  font-size: 2.625rem;
  cursor: pointer;
  position: absolute;
  z-index: 100;

  &:hover {
    color: #fade01;
  }
`;

Highlights.Text = styled.p`
  -webkit-text-stroke: 2px black;
  margin: 0;
  pointer-events: none;
`;

Highlights.ProjectTitle = styled.span`
  font-style: italic;
`;

Highlights.BookOfMine = styled(Highlights.TextGroup)`
  top: 65%;
  left: 9%;
`;

Highlights.Curatorial = styled(Highlights.TextGroup)`
  top: 12%;
  left: 20%;
`;

Highlights.MatterFarm = styled(Highlights.TextGroup)`
  top: 18%;
  left: 35%;
`;

Highlights.MutantGarden = styled(Highlights.TextGroup)`
  top: 46%;
  left: 48%;
`;

Highlights.ElectronicDiaries = styled(Highlights.TextGroup)`
  top: 57%;
  left: 72%;
`;

Highlights.TTTTT = styled(Highlights.TextGroup)`
  top: 15%;
  left: 82%;
`;

Highlights.Credits = styled(Highlights.TextGroup)`
  top: 88%;
  left: 92%;
`;

export default Highlights;
