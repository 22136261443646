import React from "react";
import MutantGarden from "./images/projects/mutantgarden.png";
import BookOfMine from "./images/projects/bookofmine.png";
import ElectronicDiaries from "./images/projects/electronicdiaries.jpg";
import MatterFarm from "./images/projects/matterfarm.png";
import TTTTT from "./images/projects/aaaa.jpg";

export default {
  mutantgarden: {
    title: 'mutant garden (2020)',
    artist: 'Harm van den Dorpel',
    link: 'https://mutant.garden/',
    description: <>
      Genetic biology is the organizing metaphor for mutant garden, which uses an algorithm
      called Cartesian genetic programming to generate an ever-evolving series of visual 
      artworks. First proposed more than 20 years ago, Cartesian genetic programming is an
      early approach to AI that uses a two-dimensional grid of nodes to represent a computer
      program, allowing it to “mutate” through an infinite range of possible permutations. In
      mutant garden, clicking one rectangular image will cause its adjacent siblings to be
      replaced with newly “mutated” offspring. Users can also select particular “mutant”
      images to be placed into a garden area, from which it can be “forked” to create
      further images; linked, or set free.
    </>,
    bio: <>
      Harm van den Dorpel (born 1981) is a Berlin-based artist whose practice includes the 
      creation of sculpture, collage, computer animation, computer generated graphics and 
      interaction design. He has exhibited widely at exhibitions including the New Museum
      in New York, MoMa PS1 in New York, Ullens Center for Contemporary Art in Beijing,
      Museum of Modern Art in Warsaw, The National Museum of Modern and Contemporary Art,
      Korea, ZKM Karlsruhe, and the Netherlands Media Art Institute in Amsterdam. In 2015,
      Van den Dorpel started Left Gallery, an online gallery that commissions, produces,
      and sells downloadable files. 
    </>,
    image: {
      src: MutantGarden,
      alt: 'Mutant Garden',
    },
  },
  bookofmine: {
    title: 'A Book of Mine, 2019–2020',
    artist: 'Xin Liu',
    link: 'https://xxxxxxxxxinliu.github.io/genome/',
    description: <>
      A Book of Mine is a book containing Xin Liu’s entire genome in base pairs. Her DNA
      was sampled and sequenced in December 2018, and the reads were mapped to the Human
      X chromosome reference sequence UCSC hs38. The bases covered by the sequencing are
      annotated in black, and those uncovered in grey. 
      <br />
      With this new commission for “Forking Piragene,” A Book of Mine is now available as
      an online, print-on-demand publication—though printing it in its entirety is a nearly
      impossible task. In its sheer scale, the project illuminates the possibilities and
      limits of genetics as a form of self-knowledge and authorship.
    </>,
    bio: <>
      Xin Liu (b. 1991, Xinjiang, China) is an artist and engineer whose works range from
      performances, apparatus, installations to scientific experiments and academic papers.
      In her practice, Xin creates experiences and artifacts to measure and modify the
      distance and tension between personal, social and technological spaces. 
      <br />
      Xin is currently the Arts Curator in Space Exploration Initiative in MIT Media Lab,
      a member of New INC in New Museum and a resident in Queens Museum Artist Studio program.
      She is recipient of the Van Lier Fellowship, Sundance New Frontiers Story Lab and Huayu
      Youth Award Finalist. Her projects have received awards in SXSW, FastCoDesign, Core77
      and her academic publications were nominated for best papers in ACM conferences. As a
      researcher, Xin has worked in Microsoft Research NYC and Asia and Google ATAP.
    </>,
    image: {
      src: BookOfMine,
      alt: 'A Book of Mine',
    },
  },
  electronicdiaries: {
    title: 'The Electronic Diaries (1984–2019)',
    artist: 'Lynn Hershman Leeson',
    link: '',
    description: <>
      Screening at Contemporary Culture Lab, Taipei: December 16, 2020<br />
Screening online: January 12, 2020, 8pm EST. <a href="https://docs.google.com/forms/d/e/1FAIpQLSfpSVHGAK4O-UPQv1Xhl4zjP7Im1zx5yjfmM1fBzS-Y4ea6AA/viewform">RSVP required.</a><br /><br />
      <br />
      In 1984, Lynn Hershman Leeson began to talk to a video camera. Over the next four decades,
      she documented her personal and artistic journey as she continually looked ahead to a
      transformative technological future while confronting her own painful past. The Electronic
      Diaries are a groudnbreaking exploration of the relationship and slippage between the real
      and virtual self, culminating with Leeson’s efforts to store her own archives in DNA, and
      to create her own custom antibody. This artistic research in biotechnology suggests new links
      between new technology and the body, personal memory and mediated archives—the thematic
      poles that have long animated Leeson’s practice.
    </>,
    bio: <>
      Over the last five decades, artist and filmmaker Lynn Hershman Leeson has been internationally
      acclaimed for her art and films. Cited as one of the most influential media artists, Hershman
      Leeson is widely recognized for her innovative work investigating issues that are now recognized
      as key to the workings of society: identity, surveillance, the relationship between humans and
      technology, and the use of media as a tool of empowerment against censorship and political repression.
    </>,
    image: {
      src: ElectronicDiaries,
      alt: 'The Electronic Diaries',
    },
  },
  ttttt: {
    title: 'DNA: TTTT / RNA: AAAA! (2020)',
    artist: 'Devin Kenny',
    link: 'https://soundcloud.com/rhizomedotorg-1/rna-ttt-dna-aaaa',
    description: <>
      While attending a rave in Houston, Texas, artist Devin Kenny gave a sample of his DNA to a startup
      company that aimed to offer a DNA-based dating app. The company later folded; in this research-based
      audio lecture and website, Kenny set out to find out what happened to it. In the process, he explores
      the implications of the DNA aftermarket, considering its crossovers with Black heritage and genealogy
      repressive policing, and asking whether it's possible to create bootlegs of his own DNA sequence. 
    </>,
    bio: <>
      Devin Kenny (American, b. 1987) is an interdisciplinary artist, writer, musician, and independent
      curator. He has collaborated with various art and music venues in New York, Chicago, Los Angeles,
      and elsewhere, including Recess; Het Roode Bioscoop; REDCAT; Freak City; Julia Stoschek Collection;
      the Core Program at the Museum of Fine Arts, Houston; SOMA Summer, Mexico City; Goethe-Institut
      New York, and MoMA PS1, which presented his solo exhibition “rootkits rootwork” in 2019. A graduate
      of Cooper Union, Kenny received his MFA in 2013 from the New Genres department at UCLA and is an
      alumnus of the Whitney Independent Study Program.
    </>,
    image: {
      src: TTTTT,
      alt: 'DNA: TTTT / RNA: AAAA! ',
    },
  },
  matterfarm: {
    title: 'Matter.Farm (2020)',
    artist: 'Sean Raspet & Francis Tseng',
    link: 'http://matter.farm/',
    description: <>
      Tseng & Raspet developed matter.farm as collaborators in Rhizome’s 7x7 program, which pairs artists
      and technologists and asks them to “make something new.” The project is an open database that
      continually generates and publishes novel molecular structures that are potential drug candidates.
      Pharmacological space––or the space of possible molecules that are likely to have pharmacological
      activity––is vast, and matter.farm aims to delineate and publish as much of this space as possible
      and it also makes potential synthesis routes available to enable researchers and others to produce
      and test the molecules matter.farm generates. The published molecules are then considered “public
      domain” by the artists, potentially making them more difficult to patent.
      <br />
      The research that led to this project began with Raspet & Tseng’s interest in simulating a human
      cell computationally, and matter.farm could be extended to biotechnological applications.
    </>,
    bio: <>
      Sean Raspet (born 1981, Washington, D.C) is an artist who works with liquid chemical formulations,
      often involving flavor and fragrance molecules. Raspet is also the co-founder of the algae-based
      food company Nonfood. Raspet’s work has been exhibited at the Museum of Contemporary Art, Chicago;
      Museum of Contemporary Art, Detroit; de Young Museum, San Francisco; Jessica Silverman Gallery,
      San Francisco; M Woods Museum, Bejing; Societé, Berlin; the 9th Berlin Biennale; Artist’s Space,
      New York; SculptureCenter, New York; and The Kitchen, New York, among others.
      <br />
      Francis Tseng  is an independent researcher at the Jain Family Institute. Prior to JFI, Francis
      was adjunct faculty at the New School, co-publisher of The New Inquiry, researcher-in-residence
      at NEW INC, and a fellow at the New York Times. He has worked on various projects such as spatial
      economic agent-based modeling, White Collar Crime Risk Zones (a critique of predictive policing),
      Bail Bloc (a collective cryptocurrency bail fund), The Founder (a dystopian business simulator),
      and other simulations around housing markets and automation. 
    </>,
    image: {
      src: MatterFarm,
      alt: 'MatterFarm',
    },
  },
  curatorial: {
    title: 'Forking Piragene',

      description: <>
      Copresented by Rhizome and C-Lab Taiwan as part of Shu Lea Cheang’s <a href="https://clab.org.tw/en/project/lab-kill-lab/">Lab Kill Lab</a>, “Forking PiraGene” is an online
      exhibition that imagines gene writing and discovery as a collective practice, positing a sci-fi
      scenario in which people propagate a “pirate gene” through informal networks of exchange.  
      <br /><br />
      The <a href="https://mauvaiscontact.info/kop/artists/html/a9_2.html">concept</a> was originally proposed by two Taiwanese artist/hackers, Shih-Chieh Ilya Li and Audrey Tang, in the early 2000s as part of Kingdom of Piracy, an online exhibition curated by Shu Lea Cheang, Yukiko Shikata, and Armin Medosch. KOP responded specifically to Taiwan’s past as a base for pirates and traders, considering this history in relation to intellectual property piracy in the digital age. Its creators wrote in 2002 that in PiraGene, “Everyone can join the action of discovery a gene inside everyone, which determine the ability of creation and cultural survival acts.”
      <br /><br />
  The works in “Forking Piragene,” by artists Devin Kenny, Harm van den Dorpel, Lynn Hershman Leeson, Sean Raspet & Francis Tseng, and Xin Liu, illuminate the personal, political, and aesthetic implications of working with genetic material. Suggesting alternatives to institutional uses of DNA for surveillance and law enforcement–or even standing in active opposition to such practices–these artists evoke the piratical possibility originally conjured by Li and Tang as part of the original Kingdom of Piracy project.
      <br /><br />
          “Forking PiraGene” is being realized now as part of <a href="http://lkl.clab.org.tw">Lab Kill Lab</a>, a 7-day, 5-work station feral lab project, conceived and directed by Shu Lea Cheang for C-LAB Technology Media Platform.
      <br /><br />
            <a href="https://rhizome.org/editorial/2020/dec/15/forking-piragene/">READ MORE</a><br /><br />
      <b>Credits</b>
      <br /><br />
      Part of Shu Lea Cheang’s Lab Kill Lab, organized by C-LAB<br />
      Curation by Michael Connor <br />
      Based on a concept by Shih-Chieh Ilya Li and Audrey Tang<br />
      Forking PiraGene online edition is copresented by Rhizome and the New Museum as part of First Look: New Art Online<br />
      Design by Phoebe Shalloway
      <br /><br />
      <b><a href="http://lkl.clab.org.tw">Lab Kill Lab</a></b>
      <br />on sites, in fields and online<br />
December 14-20, 2020
<br />
      Concept & direction/ CHEANG Shu Lea<br /><br />
      Production Team/ Escher TSAI, Monique CHIANG, CHEN Ping-Yi, TSAI Chi-Hung, HSIEH Che-Min, CHEN Mei-Hsuan
      <br /><br />
    </>,
  },
  credits: {
    title: 'Credits',
    description: <>
 <b>Credits</b>
      <br /><br />
      Part of Shu Lea Cheang’s Lab Kill Lab, organized by C-LAB<br />
      Curation by Michael Connor <br />
      Based on a concept by Shih-Chieh Ilya Li and Audrey Tang<br />
      Forking PiraGene online edition is copresented by Rhizome and the New Museum as part of First Look: New Art Online<br />
      Design by Phoebe Shalloway
      <br /><br />
      <b><a href="http://lkl.clab.org.tw">Lab Kill Lab</a></b>
      <br />on sites, in fields and online<br />
December 14-20, 2020
<br />
      Concept & direction/ CHEANG Shu Lea<br /><br />
      Production Team/ Escher TSAI, Monique CHIANG, CHEN Ping-Yi, TSAI Chi-Hung, HSIEH Che-Min, CHEN Mei-Hsuan
      <br /><br />
    </>,
  },
}
